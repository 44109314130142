import React from "react";
import { Calendar } from "lucide-react";

const events = [
  {
    title: "Music Workshop",
    date: "2024-05-15",
    location: "Ezoona Sound Studio",
  },
  {
    title: "Live Performance",
    date: "2024-06-20",
    location: "Bujumbura City Hall",
  },
  {
    title: "Artist Showcase",
    date: "2024-07-10",
    location: "Ezoona Sound Studio",
  },
];

function Events() {
  return (
    <div className="container mx-auto py-12">
      <h2 className="text-3xl font-bold mb-8 text-center" data-aos="fade-up">
        Upcoming Events
      </h2>
      <div className="space-y-6">
        {events.map((event, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-lg shadow-md flex items-center"
            data-aos="fade-up"
            data-aos-delay={index * 100}
          >
            <Calendar className="w-12 h-12 text-yellow-500 mr-4" />
            <div>
              <h3 className="text-xl font-semibold mb-2">{event.title}</h3>
              <p className="text-gray-600">Date: {event.date}</p>
              <p className="text-gray-600">Location: {event.location}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Events;
