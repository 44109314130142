import React from "react";
import { Users } from "lucide-react";

const producers = [
  { name: "Producer 1", specialty: "Hip-Hop" },
  { name: "Producer 2", specialty: "R&B" },
  { name: "Producer 3", specialty: "Afrobeats" },
  { name: "Marketing Agent", specialty: "Artist Development" },
];

function ProducersSection() {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center" data-aos="fade-up">
          Our Team
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {producers.map((producer, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md"
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <Users className="w-12 h-12 mb-4 text-yellow-500 mx-auto" />
              <h3 className="text-xl font-semibold mb-2 text-center">
                {producer.name}
              </h3>
              <p className="text-gray-600 text-center">{producer.specialty}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProducersSection;
