import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { LogIn, XCircle } from "lucide-react";
import axios from "axios";
import { toast } from "react-toastify"; // Importing React Toastify

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    phone: "",
    profilePhoto: null,
  });
  const location = useLocation();

  // Create refs for form fields
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const phoneRef = useRef(null);
  const profilePhotoRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleLoginModal = () => {
    setIsLoginOpen(!isLoginOpen);
    setFormData({ email: "", password: "", phone: "", profilePhoto: null });
  };

  const closeModals = () => {
    setIsLoginOpen(false);
    setIsRegister(false);
  };

  const isActive = (path) => {
    return location.pathname === path
      ? "bg-yellow-700 text-white"
      : "text-white";
  };

  // Handle input change
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  // Validate form fields
  const validateFields = () => {
    let isValid = true;

    if (!formData.email) {
      emailRef.current.classList.add("border-red-500"); // Highlight field in red
      isValid = false;
    } else {
      emailRef.current.classList.remove("border-red-500");
    }

    if (!formData.password) {
      passwordRef.current.classList.add("border-red-500");
      isValid = false;
    } else {
      passwordRef.current.classList.remove("border-red-500");
    }

    if (isRegister) {
      if (!formData.phone) {
        phoneRef.current.classList.add("border-red-500");
        isValid = false;
      } else {
        phoneRef.current.classList.remove("border-red-500");
      }

      if (!formData.profilePhoto) {
        profilePhotoRef.current.classList.add("border-red-500");
        isValid = false;
      } else {
        profilePhotoRef.current.classList.remove("border-red-500");
      }
    }

    return isValid;
  };

  // Login function
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      const response = await axios.post("YOUR_LOGIN_API_ENDPOINT", {
        email: formData.email,
        password: formData.password,
      });
      toast.success("Logged in successfully!");
      console.log(response.data); // Handle successful login (e.g., store token)
      closeModals();
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
    }
  };

  // Register function
  const handleRegister = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      toast.error("Please fill in all fields.");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email);
    formDataToSend.append("password", formData.password);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("profilePhoto", formData.profilePhoto);

    try {
      const response = await axios.post(
        "YOUR_REGISTER_API_ENDPOINT",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Registered successfully!");
      console.log(response.data); // Handle successful registration
      closeModals();
    } catch (error) {
      toast.error("Registration failed. Please try again.");
    }
  };

  return (
    <>
      <header className="bg-yellow-500 text-white p-4" data-aos="fade-down">
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/" className="flex items-center" onClick={closeMenu}>
            <img
              src="/EzoonaLogo.png"
              alt="Ezoona Sound Logo"
              className="w-8 h-8 mr-2"
            />
            <h1 className="text-2xl font-bold">Ezoona Sound</h1>
          </Link>

          {/* Mobile menu button */}
          <button
            onClick={toggleMenu}
            className="block lg:hidden text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              />
            </svg>
          </button>

          {/* Navigation */}
          <nav
            className={`${
              isOpen ? "block" : "hidden"
            } lg:flex bg-yellow-600 lg:bg-transparent mt-4 lg:mt-0 lg:space-x-4`}
          >
            <ul className="lg:flex space-y-4 lg:space-y-0 lg:space-x-4">
              <li>
                <Link
                  to="/"
                  className={`block px-4 py-2 rounded ${isActive("/")}`}
                  onClick={closeMenu}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/works"
                  className={`block px-4 py-2 rounded ${isActive("/works")}`}
                  onClick={closeMenu}
                >
                  Works
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className={`block px-4 py-2 rounded ${isActive("/services")}`}
                  onClick={closeMenu}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/events"
                  className={`block px-4 py-2 rounded ${isActive("/events")}`}
                  onClick={closeMenu}
                >
                  Events
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className={`block px-4 py-2 rounded ${isActive("/contact")}`}
                  onClick={closeMenu}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>

          {/* Login button */}
          <button
            onClick={toggleLoginModal}
            className="text-white flex items-center"
          >
            <LogIn className="mr-1" />
            Login
          </button>
        </div>
      </header>

      {/* Login Modal */}
      {isLoginOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={closeModals}
        >
          <div
            className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-2xl font-bold mb-4">
              {isRegister ? "Register" : "Login"}
            </h2>
            <form onSubmit={isRegister ? handleRegister : handleLogin}>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  ref={emailRef} // Attach ref
                  id="email"
                  className="w-full px-4 py-2 border rounded-full"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  ref={passwordRef} // Attach ref
                  id="password"
                  className="w-full px-4 py-2 border rounded-full"
                  placeholder="Enter your password"
                />
              </div>
              {isRegister && (
                <>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="phone"
                    >
                      Phone
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      ref={phoneRef} // Attach ref
                      id="phone"
                      className="w-full px-4 py-2 border rounded-full"
                      placeholder="Enter your phone number"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="profilePhoto"
                    >
                      Profile Photo
                    </label>
                    <input
                      type="file"
                      name="profilePhoto"
                      onChange={handleChange}
                      ref={profilePhotoRef} // Attach ref
                      id="profilePhoto"
                      className="w-full px-4 py-2 border rounded-full"
                    />
                  </div>
                </>
              )}
              <button
                type="submit"
                className="bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600"
              >
                {isRegister ? "Register" : "Login"}
              </button>
            </form>
            <p className="mt-4">
              {isRegister ? (
                <>
                  Already have an account?{" "}
                  <button
                    onClick={() => setIsRegister(false)}
                    className="text-blue-500"
                  >
                    Login
                  </button>
                </>
              ) : (
                <>
                  Don't have an account?{" "}
                  <button
                    onClick={() => setIsRegister(true)}
                    className="text-blue-500"
                  >
                    Register
                  </button>
                </>
              )}
            </p>
            <button onClick={closeModals} className="absolute top-2 right-2">
              <XCircle className="text-red-500" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
