import React from "react";

const works = [
  { title: "Album Production", artist: "Artist Name 1", year: 2023 },
  { title: "Single Release", artist: "Artist Name 2", year: 2024 },
  { title: "Music Video", artist: "Artist Name 3", year: 2023 },
];

function Works() {
  return (
    <div className="container mx-auto py-12">
      <h2 className="text-3xl font-bold mb-8 text-center" data-aos="fade-up">
        Our Works
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {works.map((work, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-lg shadow-md"
            data-aos="fade-up"
            data-aos-delay={index * 100}
          >
            <h3 className="text-xl font-semibold mb-2">{work.title}</h3>
            <p className="text-gray-600">Artist: {work.artist}</p>
            <p className="text-gray-600">Year: {work.year}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Works;
