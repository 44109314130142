import React from "react";
import { Link } from "react-router-dom";
import ProducersSection from "../components/ProducersSection";

function Home() {
  return (
    <div>
      <section className="py-20 bg-gradient-to-r from-yellow-400 to-yellow-600 text-white">
        <div className="container mx-auto text-center" data-aos="fade-up">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Welcome to Ezoona Sound
          </h1>
          <p className="text-xl md:text-2xl mb-8">
            Empowering and Innovating the Sound of Burundi
          </p>
          <Link
            to="/contact"
            className="bg-white text-yellow-500 py-2 px-6 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300"
          >
            Get Started
          </Link>
        </div>
      </section>
      <ProducersSection />
    </div>
  );
}

export default Home;
