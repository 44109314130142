import React from "react";
import { Mic, Music, Video, Users } from "lucide-react";

const services = [
  {
    title: "Music Production",
    icon: Music,
    description: "State-of-the-art music production services",
  },
  {
    title: "Vocal Recording",
    icon: Mic,
    description: "Professional vocal recording and editing",
  },
  {
    title: "Music Video Production",
    icon: Video,
    description: "High-quality music video production",
  },
  {
    title: "Artist Development",
    icon: Users,
    description: "Comprehensive artist development programs",
  },
];

function Services() {
  return (
    <div className="container mx-auto py-12">
      <h2 className="text-3xl font-bold mb-8 text-center" data-aos="fade-up">
        Our Services
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {services.map((service, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-lg shadow-md flex items-start"
            data-aos="fade-up"
            data-aos-delay={index * 100}
          >
            <service.icon className="w-12 h-12 text-yellow-500 mr-4" />
            <div>
              <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
